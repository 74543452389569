import React from 'react'
import "../../Pages/Aboutus/Aboutus.css"
import Images from '../../constant/Images'
import { motion} from "framer-motion"
import { useInView } from "react-intersection-observer";


const Ourcompany = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  return (
    <div className="about_company">
        <div className="abo">
            <div className="about_company_right">
                <motion.img src={Images.honda2} alt="" 
                          variants={{
                            hidden: { opacity: 0, y:50},
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial= "hidden"
                          animate="visible" 
                          transition={{ duration: 1.3, delay: 0.55 }}/>
                <h2 className='number'>01</h2>

            </div>
            <motion.div className="about_company_left"  ref={ref} 
                          variants={{
                            hidden: { opacity: 0, y:50},
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          // animate="visible"
                          animate={inView ? "visible" : "hidden"}
                          transition={{ duration: 1.3, delay: 0.25 }}>
                            
                <motion.h1 ref={ref}
                          variants={{
                            hidden: { opacity: 0, y:10},
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          // animate="visible"
                          animate={inView ? "visible" : "hidden"}
                          transition={{ duration: 1, delay: 0.35 }}>ABOUT OUR COMPANY</motion.h1>
                <div className="unline"></div>
                <p>Welcome to N A F Auto Parts, your trusted supplier of high-quality auto parts in Dubai, specializing exclusively in Honda vehicles. With years of experience in the auto parts industry, we understand our customers' needs and strive to provide the best service possible.</p>
                <p>Our mission is to deliver premium-quality parts, and our goal is to continuously innovate and create durable new products that meet and exceed our customers' expectations.</p>

                <p>Hashimoto is our premium brand, offering high-performance auto parts designed to surpass industry standards. Our Hashimoto brake pads and oil filters are made from the highest quality materials sourced from Malaysia, China, Thailand, and Taiwan, ensuring durability and optimal performance for all vehicle types. For those seeking innovative solutions for modern vehicles, Wareld provides cutting-edge products that blend quality and innovation.</p>
                <p>Choose N A F Auto Parts for all your Honda parts needs and experience the difference in quality and service.</p>
            </motion.div>         
        </div>
      </div>
  )
}

export default Ourcompany