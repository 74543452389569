import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAGtbLmxEeIo6GlbBuGaU83R4Xc8r3yxxI",
  authDomain: "nafauto-fb59b.firebaseapp.com",
  projectId: "nafauto-fb59b",
  storageBucket: "nafauto-fb59b.appspot.com",
  messagingSenderId: "680931400221",
  appId: "1:680931400221:web:10777a7b0cd34f624c84f4",
  measurementId: "G-16LC6TJRY0"

};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);


export { db, storage, auth };