import React, { useContext, useEffect, useState } from 'react'
import "./Frontadmin.css"
import Addproduct_admin from '../Addproduct_admin/Addproduct_admin'
import Listproduct_admin from '../Listproduct_admin/Listproduct_admin'
import { ShopContext } from '../../context/ShopContext'

const Frontadmin = () => {
  const {setproduct_show, product_show, } = useContext(ShopContext);
  
  return (
    <div className='Frontadmin'>
        <div>
          {product_show === true ? <Listproduct_admin /> : <Addproduct_admin/> }
        </div>
    </div>
  )
}

export default Frontadmin