import React, { useEffect } from 'react'
import "./Aboutus.css"
import Ourcompany from '../../containers/aboutus_section/Ourcompany'
import Banner_about from '../../containers/aboutus_section/Banner_about'
import Logos from '../../containers/aboutus_section/logos/Logos'
import Whatwedo from '../../containers/aboutus_section/Whatwedo/Whatwedo'

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [])
  
  return (
    <div className='aboutus_mian'>
      
      <Banner_about />
      <Ourcompany />
      <Logos />
      <Whatwedo />
    </div>
  )
}

export default Aboutus