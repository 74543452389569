import React from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from './common/layout';
import Products from './Pages/Products/Products';
import Shop from './Pages/Shop/Shop';
import ShopCategory from './Pages/ShopCategory/ShopCategory';
import Cart from './Pages/Cart/Cart';
import LoginSignup from './Pages/LoginSignup/LoginSignup';
import ShopContextProvider from './context/ShopContext';
import Frontadmin from './Pages/Admin/Frontadmin';
import Addproduct_admin from './Pages/Addproduct_admin/Addproduct_admin';
import Listproduct_admin from './Pages/Listproduct_admin/Listproduct_admin';
// import Aboutus from './Pages/Aboutus/Aboutus';
import Contactus from './Pages/Contactus/Contactus';
import EditProduct from './Pages/EditProduct/EditProduct';
import Aboutus from './Pages/Aboutus/Aboutus'


import { ToastContainer, toast } from 'react-toastify';
import ShopGroup from './Pages/ShopCategory/ShopGroup';
import Adminlogin from './Admin_protected_login/Adminlogin/Adminlogin';
import Excelupload from './Pages/Excelupload/Excelupload';
import AdminRoutes from './Admin_protected_login/AdminRoutes';
import Home from './Pages/Home/Home';

function App() {
  // category banner
  const AIR_AC_FILTER_COMPONENTS_banner= "AIR & AC FILTER COMPONENTS"
  const ALTERNATOR_AND_STARTER_banner= "ALTERNATOR & STARTER"
  const BODY_PARTS_banner= "BODY PARTS"
  const BRAKE_PARTS_banner= "BRAKE PARTS"

  const DRIVE_SHAFT_banner= "DRIVE SHAFT"
  const ELECTRICAL_PARTS_banner= "ELECTRICAL PARTS"
  const HEAD_GASKET_AND_KIT_banner= "HEAD GASKET & KIT"
  const IGNITION_COIL_banner= "IGNITION COIL"

  const MOUNTINGS_banner= "MOUNTINGS"
  const PRESSURE_SWITCH_AND_SENSORS_banner= "PRESSURE SWITCH & SENSORS"
  const STEERING_PARTS_banner= "STEERING PARTS"
  const SUSPENSION_PARTS_banner= "SUSPENSION_PARTS"

  const TANK_banner= "TANK"
  const TENSIONER_AND_BEARING_banner= "TENSIONER & BEARING"
  const VVT_GASKET_SPOOL_ENGINE_PARTS_banner= "VVT GASKET, SPOOL & ENGINE PARTS"
  const WATER_PUMP_COOLING_PARTS_banner= "WATER PUMP & COOLING PARTS"
  const WHEEL_BEARING_AND_DISK_ROTOR_banner= "WHEEL BEARING & DISK ROTOR"


  // group/brand banner
  const honda_banner= "Honda"
  const wareld_banner= "Wareld"
  const hashimoto_banner= "Hashimoto"


  // const ProtectedRoute = ({ element, ...rest }) => {
  //   const isAuthenticated = localStorage.getItem('adminAuthenticated') === 'true';
  
  //   return (
  //     <Route
  //       {...rest}
  //       element={isAuthenticated ? element : <Navigate to="/adminlogin" />}
  //     />
  //   );
  // };




  return (
    <div className="App">
      <ShopContextProvider>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />

            <Route path="/Shop" element={<Shop />} />

            {/* categories start */}
            <Route path="/AIR_AC_FILTER_COMPONENTS" element={<ShopCategory banner={AIR_AC_FILTER_COMPONENTS_banner} Category="AIR_AC_FILTER_COMPONENTS" />} />
            <Route path="/ALTERNATOR_AND_STARTER" element={<ShopCategory banner={ALTERNATOR_AND_STARTER_banner} Category="ALTERNATOR_AND_STARTER" />} />
            <Route path="/BODY_PARTS" element={<ShopCategory banner={BODY_PARTS_banner} Category="BODY_PARTS" />} />
            <Route path="/BRAKE_PARTS" element={<ShopCategory banner={BRAKE_PARTS_banner} Category="BRAKE_PARTS" />} />
            <Route path="/DRIVE_SHAFT" element={<ShopCategory banner={DRIVE_SHAFT_banner} Category="DRIVE_SHAFT" />} />
            <Route path="/ELECTRICAL_PARTS" element={<ShopCategory banner={ELECTRICAL_PARTS_banner} Category="ELECTRICAL_PARTS" />} />
            <Route path="/HEAD_GASKET_AND_KIT" element={<ShopCategory banner={HEAD_GASKET_AND_KIT_banner} Category="HEAD_GASKET_AND_KIT" />} />
            <Route path="/IGNITION_COIL" element={<ShopCategory banner={IGNITION_COIL_banner} Category="IGNITION_COIL" />} />
            <Route path="/MOUNTINGS" element={<ShopCategory banner={MOUNTINGS_banner} Category="MOUNTINGS" />} />
            <Route path="/PRESSURE_SWITCH_AND_SENSORS" element={<ShopCategory banner={PRESSURE_SWITCH_AND_SENSORS_banner} Category="PRESSURE_SWITCH_AND_SENSORS" />} />
            <Route path="/STEERING_PARTS" element={<ShopCategory banner={STEERING_PARTS_banner} Category="STEERING_PARTS" />} />
            <Route path="/SUSPENSION_PARTS" element={<ShopCategory banner={SUSPENSION_PARTS_banner} Category="SUSPENSION_PARTS" />} />
            <Route path="/TANK" element={<ShopCategory banner={TANK_banner} Category="TANK" />} />
            <Route path="/TENSIONER_AND_BEARING" element={<ShopCategory banner={TENSIONER_AND_BEARING_banner} Category="TENSIONER_AND_BEARING" />} />
            <Route path="/VVT_GASKET_SPOOL_ENGINE_PARTS" element={<ShopCategory banner={VVT_GASKET_SPOOL_ENGINE_PARTS_banner} Category="VVT_GASKET_SPOOL_ENGINE_PARTS" />} />
            <Route path="/WATER_PUMP_COOLING_PARTS" element={<ShopCategory banner={WATER_PUMP_COOLING_PARTS_banner} Category="WATER_PUMP_COOLING_PARTS" />} />
            <Route path="/WHEEL_BEARING_AND_DISK_ROTOR" element={<ShopCategory banner={WHEEL_BEARING_AND_DISK_ROTOR_banner} Category="WHEEL_BEARING_AND_DISK_ROTOR" />} />
            {/* categories start */}

            {/* BRAND/GROUP start */}
            <Route path="/Honda" element={<ShopGroup banner={honda_banner} Group="HONDA" />} />
            <Route path="/Wareld" element={<ShopGroup banner={wareld_banner} Group="WARELD" />} />
            <Route path="/Hashimoto" element={<ShopGroup banner={hashimoto_banner} Group="HASHIMOTO" />} />
            {/* BRANd/GROUP end */}


            <Route path="/Products" element={<Products />} >
                <Route path=':ProductsId' element={<Products />} />
            </Route>
            <Route path="/Cart" element={<Cart />} />
            <Route path="/Login" element={<LoginSignup />} />
            <Route path="/Aboutus" element={<Aboutus />} />
            <Route path="/Contactus" element={<Contactus />} />



            

                         {/* Admin Routes */}
              <Route path="/adminlogin" element={<Adminlogin />} />
              <Route path="/admin/*" element={<AdminRoutes />} /> {/* AdminRoutes for protecting admin routes */}
            </Route>

        </Routes>
      </BrowserRouter>
      </ShopContextProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
