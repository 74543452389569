import React from 'react'
import "./Cart.css"
import Cartitem from '../../containers/Cartitems/Cartitem'

const Cart = () => {
  return (
    <div>
      <Cartitem />
      
    </div>
  )
}

export default Cart
