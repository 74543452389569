import React, { useContext, useEffect, useState } from 'react';
import "./Cartitem.css";
import { ShopContext } from '../../context/ShopContext';
import { IoCloseOutline } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Table } from 'react-bootstrap';
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { TbMathGreater } from "react-icons/tb";


const Cartitem = () => {
  const { getTotalCartAmount, all_products, cartItems, addToCart, removeFromCart } = useContext(ShopContext);
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    address: '',
    email: '',
    phone: '',
    whatsapp: '',
    message: '',
    orders: ''
  });
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const [ref2, inView2] = useInView({
    triggerOnce: true,
  });
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [])

  useEffect(() => {
    const orders = all_products
      .filter(product => cartItems[product.id] > 0)
      .map((product, index) => {
        return `Order ${index + 1}:
        Product ID: ${product.id}
        Description: ${product.description}
        Code: ${product.Code}
        Quantity: ${cartItems[product.id]}
        Total: aed${product.Price1 * cartItems[product.id]}
        `;
      })
      .join('\n\n'); // Join each order with a double newline

    setFormData(prevFormData => ({
      ...prevFormData,
      orders
    }));
  }, [all_products, cartItems]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEnquirySubmit = (e) => {
    e.preventDefault();
    // Add all items to the cart
    all_products.forEach(product => {
      if (cartItems[product.id] > 0) {
        addToCart(product.id);
      }
    });
    // Proceed with form submission
    e.target.submit();
  };

  const Proceed = () => {
    toast.info("Please fill the form and click send enquiry");
  }

  const handleNumberInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  };

  return (
    <div>
      {/* Breadcrumb Section Begin */}
      <div className="breadcrumb-option">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 bread">
              <div className="breadcrumb__text">
                <h4>Enquiry Basket</h4>
                <div className="breadcrumb__links">
                  <Link to="/">Home</Link> <span> / Shop</span> <span> / Enquiry Basket</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb Section End */}

      <div className="shopping-cart spad">
        <div className="container">
          <div className="row">
            <motion.div className="col-lg-8" ref={ref}
                          variants={{
                            hidden: { opacity: 0, y: 25 },
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          // animate="visible"
                          animate={inView ? "visible" : "hidden"}
                          transition={{ duration: 1.3, delay: 0.65 }}>
              <div className="shopping__cart__table">
                {/* /bootsrap cart table start */}
                <Table size="sm" className='fill_view'>
                  <thead>
                    <tr >
                      {/* <th>#</th> */}
                      <th className='cart_prduct'>Product</th>
                      <th className='cart_quantity'>Quantity</th>
                      <th>Total</th>
                      <th></th>

                    </tr>
                  </thead>
                  <tbody>
                  {all_products.map((e) => {
                    if (cartItems[e.id] > 0) {
                      return (
                        <tr key={e.id}>
                          <td className='cart_col1'>
                              <img className='cartimg' src={e.imageURL} alt="" />
                              <div className="product__cart__item__text">
                                  <h6>{e.description}</h6>
                                  <h5>{e.Code}</h5>
                              </div>
                          </td>
                          <td className='cart_quantity'>
                              <div className="pro-qty-2 quant">
                                <span className='op' onClick={() => removeFromCart(e.id)}>-</span>
                                <div className="in_">{cartItems[e.id]}</div>
                                <span className='op' onClick={() => addToCart(e.id)}>+</span>
                              </div>
                          </td>
                          {/* <td className='cart_tot'>AED {e.Price1 * cartItems[e.id]}</td> */}
                          <td className='cart_tot'>nil</td>
                          <td className='cart_rem' onClick={() => { removeFromCart(e.id) }}><IoCloseOutline className='removeicon' /></td>
                        </tr>
                     )
                    }
                    return null;
                  })}
                  </tbody>
                </Table>
                {/* /bootsrap cart table end */}
                {/* mobileview table start*/}
                <div className="mob_cart_main" >
                    {all_products.map((e) => {
                        if (cartItems[e.id] > 0) {
                          return (
                                <div className="mob_cart1" key={e.id}>
                                  <div className="mob_cartsec1">
                                    <div className='imgdiv'><img src={e.imageURL} alt="" /></div>
                                    <div className="quant_amt">
                                        <div className="pro-qty-2 quant">
                                              <span className='op' onClick={() => removeFromCart(e.id)}>-</span>
                                              {/* <input className='in_cart in_cart2' type="text" value={cartItems[e.id]} readOnly /> */}
                                              <div className="in_cart in_cart2">{cartItems[e.id]}</div>

                                              <span className='op' onClick={() => addToCart(e.id)}>+</span>
                                        </div>
                                        {/* <div className="amt">AED {e.Price1 * cartItems[e.id]}</div> */}
                                        <div className="amt">nil</div>

                                    </div>
                                    <div className='remdiv'><IoCloseOutline className='removeicon2' /></div>
                                  </div>
                                  <div className="mob_cartsec2">                                  
                                                <h6>{e.description}</h6>
                                                <h5>{e.Code}</h5>                                       
                                  </div>
                                </div>                             
                        )
                      }
                      return null;
                    })}
                 </div>
                {/* mobileview table end*/}
              </div>
              <div className="con_proc_btn">
                  {/* <div className="continue__btn">
                    <Link to={"/"}><button className='continue__btn_a'>Continue Shopping</button></Link>
                  </div> */}
                  <div className="continue__btn">
                  <button className='continue__btn_a' onClick={() => navigate(-1)}>Continue Shopping</button> {/* Updated button */}
                </div>
                  <div className="continue__btn update__btn">
                    <button className='continue__btn_proceed' onClick={Proceed}>Proceed</button>
                  </div>
              </div>
            </motion.div>
            <motion.div className="col-lg-4" ref={ref2}
                          variants={{
                            hidden: { opacity: 0, y: 25 },
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          // animate="visible"
                          animate={inView2 ? "visible" : "hidden"}
                          transition={{ duration: 1.3, delay: 1 }}>
              <form className="cart__total_form" action="https://api.web3forms.com/submit" method="POST" onSubmit={handleEnquirySubmit}>
                <input type="hidden" name="access_key" value="36c380d3-e64e-45e2-a924-0e068ebf74f5" />

                {/* Include order details in a hidden field */}
                <input type="hidden" name="orderDetails" value={formData.orders} />
                <input type="hidden" name="subject" value="new enquiry" /> {/* Hidden input for the subject */}
                <h6>Please fill the form to send Enquiry</h6>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter your Name*"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder="Enter your Company Name"
                />
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  placeholder="Enter your Address*"
                  required
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your Email Address*"
                  required
                />
                <input
                  type="number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Enter your Phone Number*"
                  onInput={handleNumberInput}  
                  required
                />
                <input
                  type="number"
                  name="whatsapp"
                  value={formData.whatsapp}
                  onChange={handleChange}
                  placeholder="Enter your WhatsApp Number*"
                  onInput={handleNumberInput}  
                />
                {/* Conditionally render the Item Codes Display Field */}
                {Object.keys(cartItems).some(id => cartItems[id] > 0) && (
                    <textarea
                      className='code_input'
                      name="itemCodes"
                      placeholder="Item Codes (e.g., CODE1, CODE2)"
                      value={all_products.filter(product => cartItems[product.id] > 0).map(product => product.Code).join(', ')}
                      readOnly // This field is read-only
                      
                    />
                  )}
                <textarea
                  name="message"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                <button className="primary-btn" type="submit">SEND ENQUIRY</button>
              </form>
            </motion.div>
          </div>

                </div>
            </div>
        </div>

  )
}

export default Cartitem
