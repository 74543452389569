    // homepage
import LOGOblack from "../Assets/images/logos/LOGO-black.png"
import LOGOred from "../Assets/images/logos/LOGO-red.png"
import LOGOwhite from "../Assets/images/logos/LOGO-white.png"

import homebanner2 from "../Assets/images/homebanner2.jpg"
import hondacarpng1 from "../Assets/images/hondacarpng1.png"

// loginpage
import loginbg from "../Assets/images/loginbg.jpg"

// contact
import contactbanner from "../Assets/images/contactbanner.jpeg"

// about us
import hashimoto from "../Assets/images/logos/hashimoto.png"
import WARELD from "../Assets/images/logos/WARELD-LOGO.png"

import honda from "../Assets/images/logos/honda.png"
import about_banner from "../Assets/images/about_banner.jpg"
import honda2 from "../Assets/images/honda2.jpg"

// homepage
import filter from "../Assets/images/filter.png"
import alternator from "../Assets/images/alternator.png"
import body from "../Assets/images/exterior.png"
import brake from "../Assets/images/brake.png"
import driveshaft from "../Assets/images/shaft.png"
import electrical_parts from "../Assets/images/electrical_parts.png"
import gasket from "../Assets/images/gasket.png"
import ignitioncoil from "../Assets/images/ignitioncoil-removebg-preview.png"

import mounting from "../Assets/images/strut-mounting.png"
import sensor from "../Assets/images/parking-sensor.png"
import steering from "../Assets/images/steering.png"
import suspension_parts from "../Assets/images/suspension_parts.png"
import tank from "../Assets/images/water-tank.png"
import bearing from "../Assets/images/bearing.png"
import water from "../Assets/images/cooling-fan.png"
import spool from "../Assets/images/spool.png"
import wheel from "../Assets/images/disc-brake.png"



// category
import noimage from "../Assets/images/noimage.png"


export default {
    // homepage
    LOGOblack,
    LOGOred,
    homebanner2,
    LOGOwhite,
    loginbg,
    contactbanner,
    hondacarpng1,

    // aboutus
    honda,
    hashimoto,
    about_banner,
    honda2,
    WARELD,

    // Category
    noimage,

    // homepage
    filter,
    alternator, 
    body, 
    brake, 
    driveshaft, 
    electrical_parts, 
    gasket, 
    ignitioncoil, 

    mounting, 
    sensor, 
    steering, 
    suspension_parts, 
    tank, 
    bearing, 
    water,
    spool,
    wheel, 


}