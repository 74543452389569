import React from 'react';
import { Navigate } from 'react-router-dom';

const AProtectedRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem('adminAuthenticated') === 'true';

  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/adminlogin" />;
};

export default AProtectedRoute;
