import React, { useContext, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { ShopContext } from '../../context/ShopContext';
import Item from '../../containers/Item/Item';
import "./ShopCategory.css";
import { SyncLoader } from 'react-spinners';

const ShopCategory = (props) => {
  const { all_products, addToCart } = useContext(ShopContext);
  const [searchInput, setSearchInput] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [visibleCount, setVisibleCount] = useState(50);
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [noItemsFound, setNoItemsFound] = useState(false);

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      const filtered = all_products.filter((product) => 
        product.description.toLowerCase().includes(searchInput.toLowerCase()) ||
        product.Code.toLowerCase().includes(searchInput.toLowerCase()) 
      ).filter((product) => product.Group === props.Group);

      setFilteredProducts(filtered);
      setVisibleCount(50); // Reset visible count on search change
      setLoading(false);
      setNoItemsFound(filtered.length === 0);
    }, 500); // Simulate loading delay

    return () => clearTimeout(timeoutId); // Clean up the timeout on component unmount or when searchInput changes
  }, [searchInput, all_products, props.Group]);

  const handleLoadMore = () => {
    setLoadMoreLoading(true);
    setTimeout(() => {
      setVisibleCount(prevCount => prevCount + 50);
      setLoadMoreLoading(false);
    }, 500); // Simulate loading delay
  };

  return (
    <div className='ShopCategory_2'>
      <div className="banner">
        <div className="banner_cat_in">
          <h4>{props.banner}</h4>
          <p>Home / Group / {props.banner}</p>
        </div>
      </div>
      <div className="search_div_cat">
            <Form className="d-flex formdiv_cat">
              <FaSearch className='ser_ic' />
              <Form.Control
                type="search"
                placeholder="Search Here"
                className="me-2 search_in_cat"
                aria-label="Search"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </Form>
          </div>
      <div className="cat_products">
        {loading ? (
          <div className='ShopCategory all_spinner'><SyncLoader color="#A90000" /></div>
        ) : (
          <>
            {filteredProducts.length === 0 ? (
              <p className='no_item'>No items found</p>
            ) : (
              filteredProducts.slice(0, visibleCount).map((item, i) => (
                <Item
                  key={i}
                  id={item.id}
                  Code={item.Code}
                  description={item.description}
                  Group={item.Group}
                  subGroup={item.subGroup}
                  Category={item.Category}
                  imageURL={item.imageURL}
                  Price1={item.Price1}
                  addToCart={addToCart}
                />
              ))
            )}
            {loadMoreLoading && <div className="loader_sp2"><SyncLoader color="#A90000" /></div>}
            {!loadMoreLoading && filteredProducts.length > visibleCount && (
              <div className="load_more">
                <button onClick={handleLoadMore}>Load More</button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ShopCategory;
