import React from 'react'
import "./Welcome.css";
import Images from '../../../constant/Images';
import { Link } from 'react-router-dom';


const Welcome = () => {
    const WEL_CAT= [{cat_name: "AIR & AC FILTER COMPONENTS", cat_path: "/AIR_AC_FILTER_COMPONENTS", cat_img: Images.filter},
                    {cat_name: "ALTERNATOR & STARTER", cat_path: "/ALTERNATOR_AND_STARTER", cat_img: Images.alternator},
                    {cat_name: "BODY PARTS", cat_path: "/BODY_PARTS", cat_img: Images.body},
                    {cat_name: "BRAKE PARTS", cat_path: "/BRAKE_PARTS", cat_img: Images.brake},
                    {cat_name: "DRIVE SHAFT", cat_path: "/DRIVE_SHAFT", cat_img: Images.driveshaft},
                    {cat_name: "ELECTRICAL PARTS", cat_path: "/ELECTRICAL_PARTS", cat_img: Images.electrical_parts},
                    {cat_name: "HEAD GASKET & KIT", cat_path: "/HEAD_GASKET_AND_KIT", cat_img: Images.gasket},
                    {cat_name: "IGNITION COIL", cat_path: "/IGNITION_COIL", cat_img: Images.ignitioncoil},

                    {cat_name: "MOUNTINGS", cat_path: "/MOUNTINGS", cat_img: Images.mounting},
                    {cat_name: "PRESSURE SWITCH & SENSORS", cat_path: "/PRESSURE_SWITCH_AND_SENSORS", cat_img: Images.sensor},
                    {cat_name: "STEERING PARTS", cat_path: "/STEERING_PARTS", cat_img: Images.steering},
                    {cat_name: "SUSPENSION PARTS", cat_path: "/SUSPENSION_PARTS", cat_img: Images.suspension_parts},
                    {cat_name: "TANK", cat_path: "/TANK", cat_img: Images.tank},
                    {cat_name: "TENSIONER & BEARING", cat_path: "/TENSIONER_AND_BEARING", cat_img: Images.bearing},
                    {cat_name: "VVT GASKET, SPOOL & ENGINE PARTS", cat_path: "/VVT_GASKET_SPOOL_ENGINE_PARTS", cat_img: Images.spool},
                    {cat_name: "WATER PUMP & COOLING PARTS", cat_path: "/WATER_PUMP_COOLING_PARTS", cat_img: Images.water},
                    {cat_name: "WHEEL BEARING & DISK ROTOR", cat_path: "/WHEEL_BEARING_AND_DISK_ROTOR", cat_img: Images.wheel}]

  return (
    <div className='elcome_main'>
        <h1>Welcome to N A F Auto Parts Dubai!</h1>
        <p>N A F Auto Parts is your trusted partner for premium-quality auto parts, specializing in Honda vehicles. Located in the heart of Dubai, we provide our customers with the highest standards of service and an exceptional selection of auto parts designed to keep your car performing at its best.</p>
        <br/>
        <h2>Quality You Can Trust</h2>
        <p>We are proud to offer a diverse range of high-performance parts through our exclusive brands, Hashimoto and Wareld. Our Hashimoto range features top-quality brake pads and oil filters, made from the finest materials sourced from Malaysia, China, Thailand, and Taiwan. These parts are designed to exceed industry standards, ensuring both safety and optimal performance.</p>
        <p>For those seeking innovative solutions for modern vehicles, our Wareld brand delivers cutting-edge technologies that combine durability and performance, designed to meet the challenges of the automotive industry’s evolving needs.</p>
        <h2>Why Choose N A F Auto Parts?</h2>
        <ul className='ul_list'>
            <li>Specialization in Honda Parts: We are experts in Honda vehicle components, offering parts specifically tailored to enhance the performance and longevity of your car.</li>
            <li>Premium Brands: Our Hashimoto and Wareld products are crafted to provide both exceptional quality and value.</li>
            <li>Extensive Range of Products: From brake systems to oil filters and more, we stock everything your vehicle requires to maintain peak performance.
            </li>
            <li>Industry Expertise: With years of experience, we have built a reputation for delivering the highest quality parts and exceptional customer service.</li>
        </ul>
        <h2>Customer-Centric Services</h2>
        <ul className='ul_list'>
            <li>Warranty & Support: All our products are backed by reliable warranties and our experienced technical support team is always available to assist you.</li>
            <li>Worldwide Shipping: No matter where you are, we can deliver our products globally.
            </li>
            <li>Online Ordering: Enjoy a seamless online shopping experience with our user-friendly website, allowing you to easily browse and order the parts you need.</li>
        </ul>
        <p>Continuous Innovation At N A F Auto Parts, we constantly work to bring the latest in automotive innovation to our customers. With our technical expertise and commitment to quality, we aim to be your first choice for all your Honda parts needs.</p>
        <div className="category_home">
            {WEL_CAT.map((index)=>{
                return(
                    <div>
                        <Link to={index.cat_path}>
                            <div className="cate1_h">
                                <img src={index.cat_img} alt="" />
                                <h3>{index.cat_name}</h3>
                            </div>
                        </Link>
                    </div>
                )}
            
            )}
            
        </div>
    </div>
  )
}

export default Welcome