import React from 'react';
import Images from '../../constant/Images';
import "./Banner.css";
import { motion } from "framer-motion";

const Banner_home = () => {
  // const text = "High Quality Automotive Parts Wholesale & Retail".split(" ");
  const text = "Ultimate Destination for Honda Parts and Modern Solutions".split(" ");


  return (
    <div className='banner_main'>
      <div className="banner_h_29">
        <div className="banner-content_h_29">
          <div className="banner-left_h_29">
            <h1>
              {text.map((el, i) => (
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 0.35,
                    delay: i / 10,
                  }}
                  key={i}
                >
                  {el}{" "}
                </motion.span>
              ))}
            </h1>
            <p>We specialize in high-quality Honda Auto Parts, ensuring top performance and reliability. Discover our premium brands and experience exceptional service.</p>
          </div>
          <div className="banner-middle_h_29">
          < motion.img src={Images.hondacarpng1} alt="Middle Section" variants={{
                              hidden: {opacity: 0, y: -50},
                              visible: {opacity: 1, y: 0}
                            }}
                            initial= "hidden"
                            animate="visible" 
                            transition={{duration: 1.3, delay: 0.25}}/>

          </div>
          <div className="banner-right_h_29"></div>
        </div>
      </div>
      
    </div>
  );
}

export default Banner_home;
