import React from 'react'
import "./Footer.css"
import { IoIosMail } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import Images from '../../constant/Images';
import { useNavigate } from 'react-router-dom';



const Footerr = () => {
  const nav = useNavigate()
  const handleEmailClick = () => {
    window.location.href = 'mailto:nafautoparts@gmail.com';
  };

  const handlePhoneClick = () => {
    window.location.href = 'tel:+97143967711';
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const homeF = () => {
    scrollToTop();
    nav("/")
  }
  const aboutF = () => {
    scrollToTop();
    nav("/Aboutus")
  }
  const contactF = () => {
    scrollToTop();
    nav("/Contactus")
  }
  const handleClick = () => {
    const url = `https://maps.app.goo.gl/KVkF3NaJBi9UQGGp7`;
    window.open(url, '_blank');
  };
  // homeF
  return (
    <div className="foot">
        <div className='footer_main'>
            <div className="footer_left">
              <div className="f_logo" onClick={homeF} style={{ cursor: 'pointer' }}>
                <img src={Images.LOGOwhite} alt="" />
                <h1>N A F AUTO  <br/>SPARE PARTS  </h1>
              </div>
              

            </div>
            <div className="footer_right">
              <div className="f_con">
                  <div className="mail" onClick={handleEmailClick} style={{ cursor: 'pointer' }}>
                    <IoIosMail className='f_con_icon1'/>
                    <p>nafautoparts@gmail.com</p>
                  </div>
                  <div className="mail" onClick={handlePhoneClick} style={{ cursor: 'pointer' }}>
                    <IoCall className='f_con_icon2'/>
                    <p>+97143967711</p>
                  </div>
                  <div className="mail" onClick={handleClick} style={{ cursor: 'pointer' }}>
                    <FaLocationDot className='f_con_icon3'/>
                    <p>Dubai, UAE</p>
                  </div>

                </div>
              {/* <h3>Region’s largest auto parts digital distributor</h3>
              <div className="footer_nav">
                  <div className="contents_f" onClick={homeF}>Home</div>
                  <div className="contents_f" onClick={aboutF}>About Us</div>
                  <div className="contents_f" onClick={contactF}>Contact Us</div>

              </div>
               */}
            </div>

        </div>
        <p>© 2024 NAF Auto Spare Parts Trading LLC. All rights reserved.</p>
    </div>
  )
}

export default Footerr
