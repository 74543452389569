import React, { useContext, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import './Navbar.css';
import { RiStickyNoteAddLine } from 'react-icons/ri';
import { ShopContext } from '../../context/ShopContext';
import Images from '../../constant/Images';
import { Link, useLocation } from 'react-router-dom';

const Navbarr = () => {
    const { getTotalCartItems } = useContext(ShopContext);
    const location = useLocation();
    const categoryPaths = [
        '/AIR_AC_FILTER_COMPONENTS', '/ALTERNATOR_AND_STARTER', '/BODY_PARTS', '/BRAKE_PARTS',
        '/DRIVE_SHAFT', '/ELECTRICAL_PARTS', '/HEAD_GASKET_AND_KIT', '/IGNITION_COIL',
        '/MOUNTINGS', '/PRESSURE_SWITCH_AND_SENSORS', '/STEERING_PARTS', '/SUSPENSION_PARTS',
        '/TANK', '/TENSIONER_AND_BEARING', '/VVT_GASKET_SPOOL_ENGINE_PARTS', '/WATER_PUMP_COOLING_PARTS', '/WHEEL_BEARING_AND_DISK_ROTOR'
    ];
    const GroupPaths = [
        '/Honda', '/Wareld', '/Hashimoto'
    ];

    const isCategoryActive = categoryPaths.includes(location.pathname);
    const isGroupActive = GroupPaths.includes(location.pathname);


    const [expanded, setExpanded] = useState(false);

    const handleNavLinkClick = () => {
        setExpanded(false);
    };

    return (
        <Navbar bg="dark" variant="dark" expand="lg" className="navbar-custom" expanded={expanded}>
            <Navbar.Brand href="/">
                {/* <img src={Images.LOGOwhite} className='logonav' alt="Logo" /> */}
                <div className="f_logo">
                <img src={Images.LOGOwhite} alt="" />
                <h1>N A F AUTO <br/>SPARE PARTS </h1>
              </div>
            </Navbar.Brand>
            <div className='cartdiv'>
                <Link to="/Cart" onClick={handleNavLinkClick}>
                    {/* <RiStickyNoteAddLine className='cart' /> */}
                    <button className='cart'>View Enquiry List</button>
                    <div className='count'>{getTotalCartItems()}</div>
                </Link>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className='toggler_navv' onClick={() => setExpanded(!expanded)} />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mx-auto nav_center_contend navc">
                    <Link to="/" className={location.pathname === '/' ? 'navelementtitle active' : 'navelementtitle'} onClick={handleNavLinkClick}>HOME</Link>
                    <Link to="/Shop" className={location.pathname === '/Shop' ? 'navelementtitle active' : 'navelementtitle'} onClick={handleNavLinkClick}>SHOP</Link>
                    <NavDropdown title={<span className={isCategoryActive ? "nav-dropdown-title active" : "nav-dropdown-title"}>CATEGORY</span>} className="category-dropdown">
                        <div className='drop_2cols'>
                            <NavDropdown.Item className={location.pathname === '/AIR_AC_FILTER_COMPONENTS' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/AIR_AC_FILTER_COMPONENTS" onClick={handleNavLinkClick}>AIR & AC FILTER COMPONENTS</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/ALTERNATOR_AND_STARTER' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/ALTERNATOR_AND_STARTER" onClick={handleNavLinkClick}>ALTERNATOR & STARTER</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/BODY_PARTS' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/BODY_PARTS" onClick={handleNavLinkClick}>BODY PARTS</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/BRAKE_PARTS' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/BRAKE_PARTS" onClick={handleNavLinkClick}>BRAKE PARTS</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/DRIVE_SHAFT' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/DRIVE_SHAFT" onClick={handleNavLinkClick}>DRIVE SHAFT</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/ELECTRICAL_PARTS' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/ELECTRICAL_PARTS" onClick={handleNavLinkClick}>ELECTRICAL PARTS</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/HEAD_GASKET_AND_KIT' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/HEAD_GASKET_AND_KIT" onClick={handleNavLinkClick}>HEAD GASKET & KIT</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/IGNITION_COIL' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/IGNITION_COIL" onClick={handleNavLinkClick}>IGNITION COIL</NavDropdown.Item>
                        </div>
                        <div className='drop_2cols'>
                            <NavDropdown.Item className={location.pathname === '/MOUNTINGS' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/MOUNTINGS" onClick={handleNavLinkClick}>MOUNTINGS</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/PRESSURE_SWITCH_AND_SENSORS' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/PRESSURE_SWITCH_AND_SENSORS" onClick={handleNavLinkClick}>PRESSURE SWITCH & SENSORS</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/STEERING_PARTS' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/STEERING_PARTS" onClick={handleNavLinkClick}>STEERING PARTS</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/SUSPENSION_PARTS' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/SUSPENSION_PARTS" onClick={handleNavLinkClick}>SUSPENSION PARTS</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/TANK' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/TANK" onClick={handleNavLinkClick}>TANK</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/TENSIONER_AND_BEARING' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/TENSIONER_AND_BEARING" onClick={handleNavLinkClick}>TENSIONER & BEARING</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/VVT_GASKET_SPOOL_ENGINE_PARTS' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/VVT_GASKET_SPOOL_ENGINE_PARTS" onClick={handleNavLinkClick}>VVT GASKET, SPOOL & ENGINE PARTS</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/WATER_PUMP_COOLING_PARTS' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/WATER_PUMP_COOLING_PARTS" onClick={handleNavLinkClick}>WATER PUMP & COOLING PARTS</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/WHEEL_BEARING_AND_DISK_ROTOR' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/WHEEL_BEARING_AND_DISK_ROTOR" onClick={handleNavLinkClick}>WHEEL BEARING & DISK ROTOR</NavDropdown.Item>

                        </div>
                    </NavDropdown>

                    {/* <NavDropdown title={<span className={isGroupActive ? "nav-dropdown-title active" : "nav-dropdown-title"}>Brands</span>} className="category-dropdown">
                            <NavDropdown.Item className={location.pathname === '/Honda' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/Honda" onClick={handleNavLinkClick}>Honda - Genuine</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/Wareld' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/Wareld" onClick={handleNavLinkClick}>Wareld</NavDropdown.Item>
                            <NavDropdown.Item className={location.pathname === '/Hashimoto' ? 'dropdown_item active' : 'dropdown_item'} as={Link} to="/Hashimoto" onClick={handleNavLinkClick}>Hashimoto</NavDropdown.Item>
                            
                    </NavDropdown> */}
                    <Link to="/Aboutus" className={location.pathname === '/Aboutus' ? 'navelementtitle active' : 'navelementtitle'} onClick={handleNavLinkClick}>ABOUT US</Link>
                    <Link to="/Contactus" className={location.pathname === '/Contactus' ? 'navelementtitle active' : 'navelementtitle'} onClick={handleNavLinkClick}>CONTACT US</Link>
                </Nav>
                {/* login-logout */}
                {/* <Nav className="ml-auto">
                    {localStorage.getItem('auth-token') ? 
                        <div className='log_div'>
                            <Button className='loginbutton' onClick={() => { localStorage.removeItem('auth-token'); window.location.replace('/'); }}>LOGOUT</Button>
                        </div>
                        : 
                        <div className='log_div'>
                            <Nav.Link as={Link} className='loginbutton' to="/Login" onClick={handleNavLinkClick}>LOGIN</Nav.Link>
                        </div>
                    }
                </Nav> */}
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Navbarr;
