import React, { createContext, useEffect, useState } from "react";
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

export const ShopContext = createContext(null);

const getDefaultCart = () => {
    let cart = {};
    // Initialize the cart with string keys, assuming up to 300 products for now
    for (let index = 0; index < 301; index++) {
        cart[String(index)] = 0;
    }
    return cart;
}

const ShopContextProvider = (props) => {
    const [product_show, setproduct_show] = useState(true);
    const [all_products, setall_products] = useState([]);
    // const [cartItems, setCartItems] = useState(getDefaultCart());
    // Save cart to localStorage whenever it changes
    const [cartItems, setCartItems] = useState(() => {
        const savedCart = localStorage.getItem('cartItems');
        return savedCart ? JSON.parse(savedCart) : getDefaultCart();
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAllProducts = async () => {
          const querySnapshot = await getDocs(collection(db, 'all_products'));
          const allProductsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setall_products(allProductsData);
          setLoading(false);
        };
    
        fetchAllProducts();
      }, []);
    
    useEffect(() => {
        setproduct_show(true);
    }, []);
    // Save cart to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const addToCart = (itemId) => {
        setCartItems((prev) => ({ ...prev, [String(itemId)]: (prev[String(itemId)] || 0) + 1 }));
    }

    const removeFromCart = (itemId) => {
        setCartItems((prev) => ({ ...prev, [String(itemId)]: Math.max((prev[String(itemId)] || 0) - 1, 0) }));
    }

    const getTotalCartAmount = () => {
        let totalAmount = Object.entries(cartItems).reduce((total, [itemId, quantity]) => {
            if (quantity > 0) {
                const itemInfo = all_products.find(product => product.id === itemId);
                if (itemInfo) {
                    total += itemInfo.Price1 * quantity;
                }
            }
            return total;
        }, 0);
        return totalAmount;
    }

    const getTotalCartItems = () => {
        let totalItem = 0;
        for (const item in cartItems) {
            if (cartItems[item] > 0) {
                totalItem += cartItems[item];
            }
        }
        return totalItem;
    }

    const contextValue = { all_products, cartItems, addToCart, removeFromCart, getTotalCartAmount, getTotalCartItems, product_show, setproduct_show };

    return (
        <ShopContext.Provider value={contextValue}>
            {props.children}
        </ShopContext.Provider>
    );
}

export default ShopContextProvider;
