import React, { useContext } from 'react'
import "./ProductDisplay.css"
import { ShopContext } from '../../context/ShopContext';

const ProductDisplay = (props) => {
    const {product}= props;
    const {addToCart} = useContext(ShopContext);

  return (
    <div className='ProductDisplay'>
        <div className="ProductDisplay_left">
            <img src={product.image} alt="" />
        </div>
        <div className="ProductDisplay_right">
            <h3>{product.Code}</h3>
            <h3>{product.description}</h3>
            
            {/* ✯✯✯✯✯(5.0) */}
            <p>price: ${product.price1}</p>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odit, nobis est eligendi quia doloremque reprehenderit commodi ut? Adipisci est odit possimus voluptatum delectus, facilis</p>
            <h5>select size</h5>
            <p>S M L XL XXL</p>
            <button onClick={()=>{addToCart(product.id)}}>Add to cart</button>
            
            <p>Group:{product.Group}</p>
            <p>subGroup:{product.subGroup}</p>



        </div>

      
    </div>
  )
}

export default ProductDisplay
