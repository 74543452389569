import React, { useContext, useEffect } from 'react'
import "./Products.css"
import { ShopContext } from '../../context/ShopContext';
import { useParams } from 'react-router-dom';
import Breadcrums from '../../containers/Breadcrums/Breadcrums';
import ProductDisplay from '../../containers/ProductDisplay/ProductDisplay';
import DescriptionBox from '../../containers/DescriptionBox/DescriptionBox';

const Products = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const {all_products} = useContext(ShopContext);
  const {ProductsId} = useParams();
  const product = all_products.find((e)=> e.id === Number(ProductsId))
  
  return (
    <div className='product'>
        <Breadcrums product={product}/>
        <ProductDisplay product={product}/>
        <DescriptionBox />
      <hr />
    </div>
  )
}

export default Products
