import React, { useContext, useState } from 'react';
import "./Listproduct_admin.css";
import Sidebar from '../../common/Sidebar/Sidebar';
import { ShopContext } from '../../context/ShopContext';
import { db, storage } from '../../firebase';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Listproduct_admin = () => {
  const { all_products, loading, setLoading } = useContext(ShopContext);
  const [editProductModal, setEditProductModal] = useState(false);
  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('');

 const groupOptions = ["HONDA", 
                        "WARELD", 
                        "HASHIMOTO"];
  const categoryOptions = [ "AIR_AC_FILTER_COMPONENTS",
                            "ALTERNATOR_AND_STARTER",
                            "BODY_PARTS",
                            "BRAKE_PARTS",
                            "DRIVE_SHAFT",
                            "ELECTRICAL_PARTS",
                            "HEAD_GASKET_AND_KIT",
                            "IGNITION_COIL",
                            "MOUNTINGS",
                            "PRESSURE_SWITCH_AND_SENSORS",
                            "STEERING_PARTS",
                            "SUSPENSION_PARTS",
                            "TANK",
                            "TENSIONER_AND_BEARING",
                            "VVT_GASKET_SPOOL_ENGINE_PARTS",
                            "WATER_PUMP_COOLING_PARTS",
                            "WHEEL_BEARING_AND_DISK_ROTOR",
                          ];
  const showConfirmDeleteModal = (productId) => {
    setSelectedProduct(productId);
    setConfirmDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteDoc(doc(db, 'all_products', selectedProduct));
      console.log('Document deleted with ID: ', selectedProduct);
      setConfirmDeleteModal(false);
      setLoading(true); // Refresh the product list
    } catch (e) {
      console.error('Error removing document: ', e);
    }
  };

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setEditProductModal(true);
  };

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Ensure selectedProduct is properly updated with the category value
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      image: imageFile,
    }));
  };

  const confirmUpdate = () => {
    setConfirmUpdateModal(true);
  };

  const updateProduct = async () => {
    setIsEditLoading(true);
    try {
      const productDoc = doc(db, 'all_products', selectedProduct.id);

      // Update the Firestore document
      await updateDoc(productDoc, {
        Code: selectedProduct.Code,
        description: selectedProduct.description,
        Group: selectedProduct.Group,
        subGroup: selectedProduct.subGroup,
        Category: selectedProduct.Category,
        Price1: selectedProduct.Price1,
      });

      // If a new image is selected, upload it to Firebase Storage
      if (selectedProduct.image) {
        const storageRef = ref(storage, `images/${selectedProduct.id}`);
        await uploadBytes(storageRef, selectedProduct.image);
        const imageURL = await getDownloadURL(storageRef);

        // Update the Firestore document with the new image URL
        await updateDoc(productDoc, {
          imageURL: imageURL,
        });

        console.log('Updated image URL: ', imageURL);
      }

      console.log('Product updated with ID: ', selectedProduct.id);
      setEditProductModal(false);
      setLoading(true); // To refresh the product list
      toast.success('Product updated successfully!');
    } catch (e) {
      console.error('Error updating product: ', e);
    } finally {
      setIsEditLoading(false);
      setConfirmUpdateModal(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // Filter products based on the search input
  // const filteredProducts = all_products.filter(item => {
  //   const searchTerm = searchInput.toLowerCase();
  //   return (
  //     item.Code.toLowerCase().includes(searchTerm) ||
  //     item.description.toLowerCase().includes(searchTerm) ||
  //     item.Group.toLowerCase().includes(searchTerm) ||
  //     item.Category.toLowerCase().includes(searchTerm)
  //   );
  // });
  const filteredProducts = all_products
  .filter(item => {
    const searchTerm = searchInput.toLowerCase();
    return (
      item.Code.toLowerCase().includes(searchTerm) ||
      item.description.toLowerCase().includes(searchTerm) ||
      item.Group.toLowerCase().includes(searchTerm) ||
      item.Category.toLowerCase().includes(searchTerm)
    );
  })
  .sort((a, b) => a.Code.localeCompare(b.Code)); // Sort by 'Code' in ascending order

  return (
    <div className='admin_main_list'>
      <Sidebar />
      <div className='listproduct_main'>
        <h3>List product</h3>
        <div className="search_div_cat">
          <Form className="d-flex formdiv_cat">
            <FaSearch className='ser_ic' />
            <Form.Control
              type="search"
              placeholder="Search Here"
              className="me-2 search_in_cat"
              aria-label="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Form>
        </div>
        <div className="table-responsive">
          <Table striped bordered hover size="sm" className='table_list'>
            <thead>
              <tr>
                <th>Product</th>
                <th>Code</th>
                <th>Description</th>
                <th>Group</th>
                <th>Category</th>
                <th>Price1</th>
                <th>Remove</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((product, index) => (
                <tr className='list_td' key={index}>
                  <td><img src={product.imageURL} alt="" className='list_img' /></td>
                  <td>{product.Code}</td>
                  <td>{product.description}</td>
                  <td>{product.Group}</td>
                  <td>{product.Category}</td>
                  <td>{product.Price1} aed</td>
                  <td><p onClick={() => showConfirmDeleteModal(product.id)} style={{ cursor: 'pointer', color: 'red' }}>x</p></td>
                  <td><button onClick={() => handleEdit(product)} style={{ width: "100px", height: "51px" }}>edit</button></td>
                  <td><a href={product.imageURL} target="_blank" rel="noopener noreferrer">
            {product.imageURL}
          </a></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      {/* Edit Product Modal */}
      {editProductModal && selectedProduct &&
        <div className='editproduct_main'>
          <h3>Edit Product</h3>

          <label htmlFor="Code">Code</label>
          <input type="text"
            name='Code'
            placeholder='Product Code'
            value={selectedProduct.Code}
            onChange={handleChange}
          /><br />
          <label htmlFor="description">Description</label>
          <input type="text"
            name='description'
            placeholder='Product Description'
            value={selectedProduct.description}
            onChange={handleChange}
          /><br />
          <label htmlFor="Group">Group</label>
          <select
            className='sel_drop_in'
            name='Group'
            value={selectedProduct.Group}
            onChange={handleChange}
          >
            <option value="" disabled>Select a group</option>
            {groupOptions.map((group) => (
              <option key={group} value={group}>
                {group}
              </option>
            ))}
          </select><br />
          <label htmlFor="subGroup">SubGroup</label>
          <input type="text"
            name='subGroup'
            placeholder='Product SubGroup'
            value={selectedProduct.subGroup}
            onChange={handleChange}
          /><br />
          <label htmlFor="Category">Category</label>
          <select
            className='sel_drop_in'
            name='Category'
            value={selectedProduct.Category || ''}  // Make sure the default value is empty string if not set
            onChange={handleChange}
          >
            <option value="" disabled>Select a category</option>
            {categoryOptions.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
          <br />
          <label htmlFor="Price1">Price</label>
          <input type="number"
            name='Price1'
            placeholder='Price'
            value={selectedProduct.Price1}
            onChange={handleChange}
          /><br />
          <div className='imgedit'>
            <label htmlFor="product_image">Product Image</label>
            <input type="file"
              name='image'
              className="file-input"
              id="product_image"
              onChange={handleImageChange}
            />
            {selectedProduct.imageURL && <img src={selectedProduct.imageURL} alt={selectedProduct.name} width="80" />}
            {selectedProduct.image && <img src={URL.createObjectURL(selectedProduct.image)} alt="Product Preview" style={{ width: '100px', height: '100px' }} />}
          </div>
          <button onClick={confirmUpdate} disabled={isEditLoading} className='up_edit'>
            {isEditLoading ? 'Updating...' : 'Update'}
          </button>
          <button onClick={() => setEditProductModal(false)} disabled={isEditLoading} className='cam_edit'>
            Cancel
          </button>
        </div>
      }

      {/* Confirmation Modal */}
      <Modal show={confirmDeleteModal} onHide={() => setConfirmDeleteModal(false)} className='mod_main'>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this product?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmDelete}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Update Modal */}
      <Modal show={confirmUpdateModal} onHide={() => setConfirmUpdateModal(false)} className='mod_main'>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to update this product?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmUpdateModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={updateProduct}>
            Yes, Update
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default Listproduct_admin;
