import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbarr from './navbar/Navbarr';
import Footerr from './footer/Footerr';

const Layout = () => {
  return (
    <div>
      <Navbarr/>
      {/* <div style={{marginTop: "97px"}}></div> */}
      <Outlet/>
      <Footerr/>
    </div>
  )
}

export default Layout;
