import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Frontadmin from '../Pages/Admin/Frontadmin';
import Addproduct_admin from '../Pages/Addproduct_admin/Addproduct_admin';
import Listproduct_admin from '../Pages/Listproduct_admin/Listproduct_admin';
import Excelupload from '../Pages/Excelupload/Excelupload';
import AProtectedRoute from './AProtectedRoute';

const AdminRoutes = () => {
  const isAuthenticated = localStorage.getItem('adminAuthenticated') === 'true';

  if (!isAuthenticated) {
    return <Navigate to="/adminlogin" />;
  }

  return (
    <Routes>
      <Route path="admin" element={<AProtectedRoute element={Frontadmin} />} />
      <Route path="Addproduct_admin" element={<AProtectedRoute element={Addproduct_admin} />} />
      <Route path="Listproduct_admin" element={<AProtectedRoute element={Listproduct_admin} />} />
      <Route path="Excelupload" element={<AProtectedRoute element={Excelupload} />} />
    </Routes>
  );
};

export default AdminRoutes;
