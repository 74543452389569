import React, { useContext, useState, useEffect } from 'react';
import "./All_products.css";
import { ShopContext } from '../../context/ShopContext';
import Item from '../Item/Item';
import { SyncLoader } from 'react-spinners'

const All_products = ({ searchInput }) => {
  const { all_products, addToCart } = useContext(ShopContext);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [visibleCount, setVisibleCount] = useState(50);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      setFilteredProducts(
        all_products.filter((product) =>
          product.description.toLowerCase().includes(searchInput.toLowerCase()) ||
          product.Code.toLowerCase().includes(searchInput.toLowerCase())
        )
      );
      setVisibleCount(50); // Reset visible count on search change
      setLoading(false);
    }, 500); // Simulate loading delay

    return () => clearTimeout(timeoutId); // Clean up the timeout on component unmount or when searchInput changes
  }, [searchInput, all_products]);

  const handleLoadMore = () => {
    setVisibleCount(prevCount => prevCount + 50);
  };

  if (loading) {
    return <div className='ShopCategory all'><SyncLoader color= "#A90000"/></div>;
  }

  return (
    <div className='ShopCategory all'>
      <div className="cat_products">
        {filteredProducts.length === 0 ? (
          <p className='no_item'>No items found</p>
        ) : (
          filteredProducts.slice(0, visibleCount).map((item, i) => (
            <Item
              key={i}
              id={item.id}
              Code={item.Code}
              description={item.description}
              Group={item.Group}
              subGroup={item.subGroup}
              Category={item.Category}
              imageURL={item.imageURL}
              Price1={item.Price1}
              addToCart={addToCart}
            />
          ))
        )}
      </div>
      {visibleCount < filteredProducts.length && (
        <div className="loadall">
          <button onClick={handleLoadMore}>Load More</button>
        </div>
      )}
      
      
    </div>
  );
}

export default All_products;

// ASCENDING ORDER ARRANGMENT

// import React, { useContext, useState, useEffect } from 'react';
// import "./All_products.css";
// import { ShopContext } from '../../context/ShopContext';
// import Item from '../Item/Item';
// import { SyncLoader } from 'react-spinners';

// const All_products = ({ searchInput }) => {
//   const { all_products, addToCart } = useContext(ShopContext);
//   const [filteredProducts, setFilteredProducts] = useState([]);
//   const [visibleCount, setVisibleCount] = useState(50);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     setLoading(true);
//     const timeoutId = setTimeout(() => {
//       // Sort products by 'Code' in ascending order, then filter by search input
//       const sortedProducts = all_products
//         .sort((a, b) => a.Code.localeCompare(b.Code)) // Sorting by 'Code'
//         .filter((product) =>
//           product.description.toLowerCase().includes(searchInput.toLowerCase()) ||
//           product.Code.toLowerCase().includes(searchInput.toLowerCase())
//         );

//       setFilteredProducts(sortedProducts);
//       setVisibleCount(50); // Reset visible count on search change
//       setLoading(false);
//     }, 500); // Simulate loading delay

//     return () => clearTimeout(timeoutId); // Clean up the timeout on component unmount or when searchInput changes
//   }, [searchInput, all_products]);

//   const handleLoadMore = () => {
//     setVisibleCount(prevCount => prevCount + 50);
//   };

//   if (loading) {
//     return <div className='ShopCategory all'><SyncLoader color= "#A90000"/></div>;
//   }

//   return (
//     <div className='ShopCategory all'>
//       <div className="cat_products">
//         {filteredProducts.length === 0 ? (
//           <p className='no_item'>No items found</p>
//         ) : (
//           filteredProducts.slice(0, visibleCount).map((item, i) => (
//             <Item
//               key={i}
//               id={item.id}
//               Code={item.Code}
//               description={item.description}
//               Group={item.Group}
//               subGroup={item.subGroup}
//               Category={item.Category}
//               imageURL={item.imageURL}
//               Price1={item.Price1}
//               addToCart={addToCart}
//             />
//           ))
//         )}
//       </div>
//       {visibleCount < filteredProducts.length && (
//         <div className="loadall">
//           <button onClick={handleLoadMore}>Load More</button>
//         </div>
//       )}
//     </div>
//   );
// }

// export default All_products;

