import React from 'react'
import "../../Pages/Aboutus/Aboutus.css"
import Images from '../../constant/Images'
import { motion} from "framer-motion"



const Banner_about = () => {
  return (
    <div className='about_banner'>
        <motion.img src={Images.about_banner} alt="" animate={{scale: 1.02 }}
                      initial={{scale: 1}}
                      transition={{duration: 1, delay: 0.1}}/>
        <div className="about_banner_contend">
          <motion.h1 animate={{scale: 1 }}
                      initial={{scale: 0.80}}
                      transition={{duration: 1, delay: 0.3}}>ABOUT US</motion.h1>
          <p>Home / Aboutus</p>
        </div>
        
    </div>
  )
}

export default Banner_about