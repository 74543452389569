import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginSignup.css';
import Images from '../../constant/Images';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginSignup = () => {
  const [state, setState] = useState('LOGIN');
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    email: '',
  });
  const navigate = useNavigate(); // useNavigate hook from react-router-dom

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const login = async () => {
    console.log('login function executed', formData);
    try {
      const response = await fetch('http://localhost:4001/login', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();

      if (responseData.success) {
        localStorage.setItem('auth-token', responseData.token);
        toast.success('Login successful!');
        if (formData.email === 'admin@admin.com' && formData.password === 'admin') {
          navigate('/admin'); // Redirect to admin route
        } else {
          navigate('/'); // Redirect to homepage after login
        }
      } else {
        // alert(responseData.error); // Display error message
        toast.error(responseData.error);
      }
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('Error logging in. Please try again.');

      // Handle error
    }
  };

  const signup = async () => {
    console.log('signup function executed', formData);
    try {
      const response = await fetch('http://localhost:4001/signup', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();

      if (responseData.success) {
        toast.success('Signup successful!');

        localStorage.setItem('auth-token', responseData.token);
        navigate('/'); // Redirect to homepage after signup
      } else {
        // alert(responseData.error); // Display error message
        toast.error(responseData.error);
      }
    } catch (error) {
      console.error('Error signing up:', error);
      // Handle error
      toast.error('Error signing up. Please try again.');
    }
  };

  return (
    <div className='LoginSignup'>
    <div className="login100-form-title">
      <div className="image-container">
        <img className='loginbg' src={Images.loginbg} alt="" />
        <span className="state-text">{state}</span>
      </div>
    </div>
    {state === 'SIGN UP' && (
      <div>
        <label className='label_filed' htmlFor="Username" >Username:</label>
        <input
          type='text'
          className="inputlogin"
          placeholder='Enter your name'
          name='username'
          value={formData.username}
          onChange={changeHandler}
        />
      </div>
    )}
    <div>
      <label className='label_filed' htmlFor="Email">Email:</label>
      <input
        className="inputlogin"
        type='email'
        placeholder='Enter your email'
        name='email'
        value={formData.email}
        onChange={changeHandler}
      />
    </div>
    <div>
        {state === 'SIGN UP' ? (
            <label className='label_filed' htmlFor="Username" >Create Password:</label>
          ):<label className='label_filed' htmlFor="Password">Password:</label>
        }
      
      <input
        type='password'
        className="inputlogin"
        placeholder='Enter your password'
        name='password'
        value={formData.password}
        onChange={changeHandler}
      />
    </div>
    <div className='btn_div'>
    {state === 'SIGN UP' && (
      <div className='cke'>
                <input type='checkbox' name='' id='' className='check' />

        <p>
          By continuing, I agree to the terms of use and privacy policy.
        </p>
      </div>
    )}
        <button className='LoginSignup_button' onClick={() => (state === 'LOGIN' ? login() : signup())}>{state}</button>
        {state === 'SIGN UP' ? (
      <p>
        Already have an account?{' '}
        <span className='log_link' onClick={() => setState('LOGIN')}>Login</span>
      </p>
    ) : (
      <p>
        Create an account?{' '}
        <span className='log_link' onClick={() => setState('SIGN UP')}>Register</span>
      </p>
    )}
    </div>
    
    
    
  </div>

 
  );
};

export default LoginSignup;
