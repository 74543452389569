import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Images from '../../constant/Images';

const Adminlogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    if (username === 'rana' && password === 'jishi') {
      localStorage.setItem('adminAuthenticated', 'true');
      navigate('/admin/Listproduct_admin');
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    // <div>
    //   <h2>Admin Login</h2>
    //   <input
    //     type="text"
    //     placeholder="Username"
    //     value={username}
    //     onChange={(e) => setUsername(e.target.value)}
    //   />
    //   <input
    //     type="password"
    //     placeholder="Password"
    //     value={password}
    //     onChange={(e) => setPassword(e.target.value)}
    //   />
    //   <button onClick={handleLogin}>Login</button>
    // </div>
    <div>
       <div className='LoginSignup'>
         <div className="login100-form-title">
           <div className="image-container">
             <img className='loginbg' src={Images.loginbg} alt="" />
             <span className="state-text">Login</span>
           </div>
         </div>
         <div>
           <label className='label_filed' htmlFor="Email">Email:</label>
           <input
             className="inputlogin"
             type='text'
             placeholder='Enter your Username'
             name='email'
             value={username}
             onChange={(e) => setUsername(e.target.value)}
           />
         </div>
         <div>
           <label className='label_filed' htmlFor="Password">Password:</label>
           <input
             type='password'
             className="inputlogin"
             placeholder='Enter your password'
             name='password'
             value={password}
             onChange={(e) => setPassword(e.target.value)}
           />
         </div>
         <div className='btn_div'>
           <button className='LoginSignup_button' onClick={handleLogin}>Login</button>
         </div>
       </div>
     </div>
  );
};

export default Adminlogin;


// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import './Adminlogin.css';
// import Images from '../../constant/Images';

// const Adminlogin = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate();

//   const handleLogin = () => {
//     if (email === 'noor@gmail.com' && password === 'naf123') {
//       navigate('/admin');
//     } else {
//       alert('Invalid email or password');
//     }
//   };

//   return (
//     <div>
//       <div className='LoginSignup'>
//         <div className="login100-form-title">
//           <div className="image-container">
//             <img className='loginbg' src={Images.loginbg} alt="" />
//             <span className="state-text">Login</span>
//           </div>
//         </div>
//         <div>
//           <label className='label_filed' htmlFor="Email">Email:</label>
//           <input
//             className="inputlogin"
//             type='email'
//             placeholder='Enter your email'
//             name='email'
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//           />
//         </div>
//         <div>
//           <label className='label_filed' htmlFor="Password">Password:</label>
//           <input
//             type='password'
//             className="inputlogin"
//             placeholder='Enter your password'
//             name='password'
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//           />
//         </div>
//         <div className='btn_div'>
//           <button className='LoginSignup_button' onClick={handleLogin}>Login</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Adminlogin;
