import React from 'react';
import "./Item.css";
import { useNavigate } from 'react-router-dom';
import { RiStickyNoteAddLine } from "react-icons/ri";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Images from '../../constant/Images';

// Default image for products without images
const defaultImageURL = Images.noimage;

const Item = (props) => {
  const { id, image, Code, description, Group, subGroup, Price1, addToCart } = props;
  const navigate = useNavigate();

  const handleEnquiry = () => {
    addToCart(id);
    navigate('/cart');
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <motion.div 
      className='item' 
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      transition={{ duration: 1.3, delay: 0.25 }}
    >
      <div className="col-lg-4 col-md-6 col-sm-6 items">
        <div className="product__item sale">
          <div className="picpad">
            <div className="product__item__pic">
              <img 
                src={props.imageURL || defaultImageURL}  // Use default image if imageURL is null
                alt={props.name || 'Default Product'} 
                className='p_imgurl' 
                width="100" 
              />
              <button className='anumatn' onClick={handleEnquiry}>Send Enquiry</button>
            </div>
          </div>
          <div className="product__item__text">
            <h6 className='item_h66' style={{ wordBreak: "break-all" }}>{props.description}</h6>
            <h5>{props.Code}</h5>
            {/* <h5 className='prize'>AED {props.Price1}</h5> */}
            <p className='product__color__select_p'>{props.Group}</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Item;
