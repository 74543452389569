import React, { useContext } from 'react';
import "./Sidebar.css";
import { useNavigate } from "react-router-dom";
import { ShopContext } from '../../context/ShopContext';
import { toast } from 'react-toastify';

const Sidebar = () => {
  const { setproduct_show } = useContext(ShopContext);
  const nav = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('adminAuthenticated');
    
    nav('/adminlogin');
  };

  return (
    <div className='sidebar'>
      <div className='item_side'>
        <h6 className='item_h6' onClick={() => { nav("/admin/Listproduct_admin") }}>List product</h6>
        <h6 className='item_h6_2' onClick={() => { nav("/admin/Addproduct_admin") }}>Add product</h6>
        <h6 className='item_h6_2' onClick={() => { nav("/admin/Excelupload") }}>excel upload</h6>
        <h6 className='item_h6_2' onClick={handleLogout}>Logout</h6>
      </div>
    </div>
  );
};

export default Sidebar;
