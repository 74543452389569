import React from 'react'
import "./Whatwedo.css"
import { motion} from "framer-motion"
import { useInView } from "react-intersection-observer";

const Whatwedo = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
      });
  return (
    <div className='Whatwedo_main'>
        <div className="whatwedi_sub">
            <h1 style={{color: "white"}}>WHAT WE DO?</h1>
            <div className="whatwedo_cards">
                <div className="whatwedo_cards_new1">
                    <motion.div className="new_l" ref={ref}
                                                    variants={{
                                                        hidden: { opacity: 0, x: -25 },
                                                        visible: { opacity: 1, x: 0 },
                                                    }}
                                                    initial="hidden"
                                                    // animate="visible"
                                                    animate={inView ? "visible" : "hidden"}
                                                    transition={{ duration: 1, delay: 0.1 }}>
                        <h5><span className='headno'>1</span>Exclusive Brands</h5>
                        <p>Featuring our own brands, Wareld and Hashimoto, known for their reliability and exceptional quality</p>
                    </motion.div>
                    <motion.div className="new_l" ref={ref}
                                                    variants={{
                                                        hidden: { opacity: 0, x: 25 },
                                                        visible: { opacity: 1, x: 0 },
                                                    }}
                                                    initial="hidden"
                                                    // animate="visible"
                                                    animate={inView ? "visible" : "hidden"}
                                                    transition={{ duration: 1, delay: 0.1 }}>
                        <h5><span className='headno'>2</span>Special Discount Offer</h5>
                        <p>We are pleased to offer special discounts on all our products, providing great value for both wholesale and retail customers. Explore our range and take advantage of these exceptional savings</p>
                    </motion.div>
                </div>
                <div className="whatwedo_cards_new1">
                    <motion.div className="new_l" ref={ref}
                                                variants={{
                                                    hidden: { opacity: 0, x: -25 },
                                                    visible: { opacity: 1, x: 0 },
                                                }}
                                                initial="hidden"
                                                // animate="visible"
                                                animate={inView ? "visible" : "hidden"}
                                                transition={{ duration: 1, delay: 0.1 }}>
                        <h5><span className='headno'>3</span>Brake Pads and Brake Shoes</h5>
                        <p>Our Hashimoto brake pads and brake shoes are designed for superior stopping power and durability.</p>
                    </motion.div>
                    <motion.div className="new_l" ref={ref}
                                                    variants={{
                                                        hidden: { opacity: 0, x: 25 },
                                                        visible: { opacity: 1, x: 0 },
                                                    }}
                                                    initial="hidden"
                                                    // animate="visible"
                                                    animate={inView ? "visible" : "hidden"}
                                                    transition={{ duration: 1, delay: 0.1 }}>
                        <h5><span className='headno'>4</span>Oil Filters</h5>
                        <p>Hashimoto oil filters ensure your engine runs smoothly, offering excellent filtration and protection</p>
                    </motion.div>
                </div>
                


            </div>
        </div>
    </div>
  )
}

export default Whatwedo