import React from 'react';
import Images from '../../constant/Images';
import "./Banner.css";
import { FaSearch } from "react-icons/fa";
import { motion } from "framer-motion";

const Banner = ({ searchInput, setSearchInput }) => {
  // const text = "High Quality Automotive Parts Wholesale & Retail".split(" ");
  const text = "Ultimate Destination for Honda Parts and Modern Solutions".split(" ");


  return (
    <div className='banner_main'>
      <div className='about_banner'>
        <motion.img src={Images.about_banner} alt="" animate={{scale: 1.02 }}
                      initial={{scale: 1}}
                      transition={{duration: 1, delay: 0.1}}/>
        <div className="about_banner_contend">
          <motion.h1 animate={{scale: 1 }}
                      initial={{scale: 0.80}}
                      transition={{duration: 1, delay: 0.3}}>SHOP</motion.h1>
          <p>Home / SHOP</p>
        </div>
        
    </div>
        <div className="search_new_29">
          <input className='in_mser'
              type="text" 
              placeholder="Search Here"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              />
          <button>
            Search<FaSearch className='ser_new' />
          </button>
        </div>
      
      
    </div>
  );
}

export default Banner;
