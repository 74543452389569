import React from 'react'
import "./Logos.css"
import Images from '../../../constant/Images'

const Logos = () => {
  return (
    <div className='logos_main'>
        <h1>Our brands</h1>
        <div className="logo_sec">
            <img src={Images.hashimoto} alt="" className='hashimoto'/>
            <img src={Images.honda} alt="" className='honda'/>
            <img src={Images.WARELD} alt="" className='wareld'/>

        </div>
        
    </div>

  )
}

export default Logos