import React from 'react'
import Banner_home from '../../containers/Banner/Banner_home'
import Welcome from './welcome/Welcome'

const Home = () => {
  return (
    <div>
        <Banner_home />
        <Welcome />
    </div>
  )
}

export default Home