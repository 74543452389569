import React, { useState } from 'react';
import "./Shop.css";
import Banner from '../../containers/Banner/Banner_shop';
// import Popular from '../../containers/popular/Popular'
// import New_collection from '../../containers/new_collection/New_collection'
// import Newsletter from '../../containers/Newsletter/Newsletter'
import All_products from '../../containers/All_products/All_products';

const Shop = () => {
  const [searchInput, setSearchInput] = useState('');

  return (
    <div>
      <Banner searchInput={searchInput} setSearchInput={setSearchInput} />
      <All_products searchInput={searchInput} />     
     </div>
  );
}

export default Shop;
