
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { db } from '../../firebase';
import { collection, getDocs, query, where, updateDoc, addDoc, doc } from 'firebase/firestore';
import Sidebar from '../../common/Sidebar/Sidebar';
import "./Excelupload.css";

const Excelupload = () => {
    const [selectedFile, setSelectedFile] = useState(null);

    // Handle file selection
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    // Handle file upload and updating values based on "Code"
    const handleFileUpload = async () => {
        if (!selectedFile) {
            alert('Please select a file first.');
            return;
        }

        const confirmUpload = window.confirm('Are you sure you want to upload this file?');
        if (!confirmUpload) return;

        const reader = new FileReader();

        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            const expectedFields = ["Code", "description", "Group", "subGroup", "Category", "Price1", "imageURL"];
            const ref = collection(db, "all_products");

            try {
                for (const item of jsonData) {
                    const productCode = item.Code;

                    console.log(`Processing product with Code: ${productCode}`);

                    // Search for the product by its "Code" in Firestore
                    const q = query(ref, where('Code', '==', productCode));
                    const querySnapshot = await getDocs(q);

                    if (!querySnapshot.empty) {
                        // If the product exists, update the document with new values from the Excel file
                        querySnapshot.forEach(async (docSnapshot) => {
                            const productDoc = doc(db, "all_products", docSnapshot.id);
                            const updatedFields = {};
                            
                            expectedFields.forEach(field => {
                                if (item[field] !== undefined && item[field] !== null) {
                                    updatedFields[field] = item[field];
                                }
                            });
                            
                            // Perform the update in Firestore
                            await updateDoc(productDoc, updatedFields);
                            console.log(`Product with Code ${productCode} updated with values:`, updatedFields);
                        });
                    } else {
                        // If the product doesn't exist, add it as a new document
                        const formattedItem = {};
                        expectedFields.forEach(field => {
                            formattedItem[field] = item[field] || null;
                        });
                        
                        await addDoc(ref, formattedItem);
                        console.log(`New product added with Code ${productCode}.`);
                    }
                }
                alert('Data successfully processed and updated in Firebase!');
            } catch (error) {
                console.error('Error uploading or updating data: ', error);
            }
        };

        reader.readAsArrayBuffer(selectedFile);
    };

    // Handle download of Firestore data as an Excel file
    const handleDownload = async () => {
        const ref = collection(db, "all_products");
        const querySnapshot = await getDocs(ref);

        // Convert Firestore data into an array of objects
        const products = querySnapshot.docs.map((doc) => doc.data());

        // Convert data into Excel format
        const worksheet = XLSX.utils.json_to_sheet(products);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

        // Generate Excel file and trigger download
        const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelFile], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'products.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className='admin_main_list'>
            <Sidebar />
            <div className='listproduct_main'>
                <h3>Upload Excel File</h3>
                <input type="file" className='up_in' onChange={handleFileChange} accept=".xlsx, .xls" />
                <button className='up_bt' onClick={handleFileUpload}>Upload File</button>

                <h3>Download Current Database as Excel</h3>
                <button className='down_bt' onClick={handleDownload}>Download Excel File</button>

                
            </div>
        </div>
    );
};

export default Excelupload;
