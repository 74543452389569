import React, { useEffect, useState, useRef } from 'react';
import "./Contactus.css";
import { IoLocationSharp } from "react-icons/io5";
import { IoIosMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import Images from '../../constant/Images';
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Contactus = () => {
  const [map, setMap] = useState("naf_deira");
  const mapRef = useRef(null); // Create a ref for the map section

  useEffect(() => {
    setMap("naf_deira");
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [])

  const handleEmailClick = () => {
    window.location.href = 'mailto:nafautoparts@gmail.com';
  };

  const handlePhoneClick = () => {
    window.location.href = 'tel:+97143967711';
  };
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const [ref2, inView2] = useInView({
    triggerOnce: true,
  });
  const [ref3, inView3] = useInView({
    triggerOnce: true,
  });

  const handleNumberInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  };


  
  const handleLocationClick = (location) => {
    setMap(location);
    if (mapRef.current) {
      // Scroll the map section into view
      mapRef.current.scrollIntoView({ behavior: 'smooth' });
      // Adjust the scroll position by scrolling a bit up
      window.setTimeout(() => {
        window.scrollBy({ top: -100, behavior: 'smooth' }); // Adjust the offset value as needed
      }, 500); // Delay to ensure `scrollIntoView` has been applied
    }
  };

  const handleClick = () => {
    const url = `https://maps.app.goo.gl/KVkF3NaJBi9UQGGp7`;
    window.open(url, '_blank');
  };
  return (
    <div className='contactus_page'>
      {/* -----------------------------------------------banner----------------------------------------------------- */}
        <div className='about_banner'>
          <motion.img src={Images.contactbanner} alt="" animate={{scale: 1.02 }}
                        initial={{scale: 1}}
                        transition={{duration: 1, delay: 0.1}}/>
          <div className="about_banner_contend">
            <motion.h1 animate={{scale: 1 }}
                        initial={{scale: 0.80}}
                        transition={{duration: 1, delay: 0.3}}>CONTACT US</motion.h1>
            <p>Home / Contactus</p>
          </div>
        
      </div>
      {/* -----------------------------------------------banner end----------------------------------------------------- */}
      <div className="contact-main">
        {/* -------------------------------------------noo.,loc,email------------------------------------------------ */}
        <div className="contact-method">
          <motion.div className="method-item" ref={ref} onClick={handleClick} style={{ cursor: 'pointer' }}
                          variants={{
                            hidden: { opacity: 0, y: 20 },
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          // animate="visible"
                          animate={inView ? "visible" : "hidden"}
                          transition={{ duration: 1.3, delay: 0.25 }}>
            <IoLocationSharp className='contact-icon contact-iconadd' />
            <span>38 C St, Silver Island Building Shop No 16 - Dubai</span>
          </motion.div>
          <motion.div className="method-item" onClick={handleEmailClick} ref={ref} style={{ cursor: 'pointer' }}
                          variants={{
                            hidden: { opacity: 0, y: 20 },
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          // animate="visible"
                          animate={inView ? "visible" : "hidden"}
                          transition={{ duration: 1.3, delay: 0.45 }}>
            <IoIosMail className='contact-icon' />
            <span>nafautoparts@gmail.com</span>
          </motion.div>
          <motion.div className="method-item" onClick={handlePhoneClick} ref={ref} style={{ cursor: 'pointer' }}
                          variants={{
                            hidden: { opacity: 0, y: 20 },
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          // animate="visible"
                          animate={inView ? "visible" : "hidden"}
                          transition={{ duration: 1.3, delay: 0.65 }}>
            <FaPhoneAlt className='contact-icon' />
            <span>+97143967711</span>
          </motion.div>
        </div>
        {/* -------------------------------------------noo.,loc,email end------------------------------------------------ */}
        {/* ----------------------------------------------------form------------------------------------------------------*/}
        <div className="contact-form-address" >
          <motion.div className="contact-form" ref={ref2}
                          variants={{
                            hidden: { opacity: 0, y: 25 },
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          // animate="visible"
                          animate={inView2 ? "visible" : "hidden"}
                          transition={{ duration: 1.3, delay: 0.65 }}>
            <form className="form" action="https://api.web3forms.com/submit" method="POST" >
              <input type="hidden" name="access_key" value="36c380d3-e64e-45e2-a924-0e068ebf74f5" />
              <input type="hidden" name="subject" value="Contact us message" /> {/* Hidden input for the subject */}
              <div className="form-group row1">
                <div className='rowcol'>
                  <label htmlFor="name">Your Name</label>
                  <input type="text" name='name' className="form-control" id="name" placeholder="Your Name" required/>
                </div>
                <div className='rowcol'>
                  <label htmlFor="number">Number</label>
                  <input type="number" name='number' className="form-control" id="number" placeholder="Number" onInput={handleNumberInput}  required/>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="email">Your Email</label>
                <input type="email" name='email' className="form-control" id="email" placeholder="Your Email" required/>
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea id="message" name='message' cols="30" rows="7" className="form-control" placeholder="Message"></textarea>
              </div>
              <div className="form-group">
                <button className="btn_msg py-3 px-5">Send Message</button>
              </div>
            </form>
          </motion.div>
          {/* ----------------------------------------------------form end--------------------------------------------------------- */}
          {/* -------------------------------------------------------location-----------------------------------------------------= */}
          <motion.div className="contact-address" ref={ref3}
                          variants={{
                            hidden: { opacity: 0, y: 25 },
                            visible: { opacity: 1, y: 0 },
                          }}
                          initial="hidden"
                          // animate="visible"
                          animate={inView3 ? "visible" : "hidden"}
                          transition={{ duration: 1.3, delay: 0.65 }}>
            <h5>ADDRESS</h5>
            <p>click the location to view the map </p>
            <div className={`address-item ${map === "naf_deira" ? 'address-item_selected' : ''}`} onClick={() => handleLocationClick("naf_deira")} style={{ cursor: 'pointer' }}>
              <h6>Deira - Head Office</h6>
              <p>38 C St, Silver Island Building Shop No 16 - Dubai</p>
            </div>
            <div className={`address-item ${map === "naf_ajman" ? 'address-item_selected' : ''}`} onClick={() => handleLocationClick("naf_ajman")} style={{ cursor: 'pointer' }}>
              <h6>Ajman</h6>
              <p>CGM7+J7J - Al Jerf Industrial 1 - Ajman</p>
            </div>
            <div className={`address-item ${map === "noor" ? 'address-item_selected' : ''}`} onClick={() => handleLocationClick("noor")} style={{ cursor: 'pointer' }}>
              <h6>Ras Al Khor</h6>
              <p>13 6b St - Ras Al Khor Industrial Area -Ras Al Khor Industrial Area 1 - Dubai</p>
            </div>
          </motion.div>
        </div>
        {/* ----------------------------------------------------map------------------------------------------------------- */}
        <div className="map" ref={mapRef}>
          {/* naf deira */}
          {map === "naf_deira" && (
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7215.780405766369!2d55.30695874284418!3d25.274278710645383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f4316aa8aecb9%3A0x5ce9abfdfee083f1!2sNAF%20AUTO%20SPARE%20PARTS!5e0!3m2!1sen!2sae!4v1721208063217!5m2!1sen!2sae" width="100%" height="650" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          )}
          {/* noor */}
          {map === "noor" && (
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.6758923644693!2d55.33042927538136!3d25.180419377721737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f67c89a5198b3%3A0x4fdd8eef7b15df8e!2sNoor%20al%20Fajr%20Honda%20Spare%20Parts%20LLC!5e0!3m2!1sen!2sae!4v1721220143125!5m2!1sen!2sae" width="100%" height="650" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          )}
          {/* ajman */}

          {map === "naf_ajman" && (
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.123389275004!2d55.51064311162142!3d25.434138021968188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5f97766d222c3%3A0xa4cba3efe9cb19f!2sN%20A%20F%20Auto%20Spare%20Parts%20Ajman!5e0!3m2!1sen!2sae!4v1721652381696!5m2!1sen!2sae" width="100%" height="650" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          )}
        </div>
      </div>
    </div>
  );
}

export default Contactus;
