import React from 'react'
import "./DescriptionBox.css"

const DescriptionBox = () => {
  return (
    <div className='DescriptionBox'>
        <div className='discrbutn'>
            <button> Decsription</button>
            <button>review</button>
        </div>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas, consectetur libero. Deleniti nihil nobis voluptatibus rerum, itaque similique quibusdam tempora doloribus, illo repellendus, adipisci dolor quidem distinctio necessitatibus at. Tempora?</p>
        
      
    </div>
  )
}

export default DescriptionBox
