import React, { useState } from 'react';
import { db, storage } from '../../firebase';
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Sidebar from '../../common/Sidebar/Sidebar';
import "./Addproduct_admin.css"

const Addproduct_admin = () => {

  const [Code, setCode] = useState('');
  const [description, setdescription] = useState('');
  const [Group, setGroup] = useState('');
  const [subGroup, setsubGroup] = useState('');
  const [Category, setCategory] = useState('');
  const [Price1, setPrice1] = useState('');
  const [image, setimage] = useState(null);

  // Define the options for the dropdowns
  const groupOptions = ["HONDA", 
                        "WARELD", 
                        "HASHIMOTO"];
  const categoryOptions = [ "AIR_AC_FILTER_COMPONENTS",
                            "ALTERNATOR_AND_STARTER",
                            "BODY_PARTS",
                            "BRAKE_PARTS",
                            "DRIVE_SHAFT",
                            "ELECTRICAL_PARTS",
                            "HEAD_GASKET_AND_KIT",
                            "IGNITION_COIL",
                            "MOUNTINGS",
                            "PRESSURE_SWITCH_AND_SENSORS",
                            "STEERING_PARTS",
                            "SUSPENSION_PARTS",
                            "TANK",
                            "TENSIONER_AND_BEARING",
                            "VVT_GASKET_SPOOL_ENGINE_PARTS",
                            "WATER_PUMP_COOLING_PARTS",
                            "WHEEL_BEARING_AND_DISK_ROTOR",
                          ];
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Confirmation alert
    if (!window.confirm('Are you sure you want to add this product?')) {
      return;
    }

    try {
      // Add document to Firestore
      const docRef = await addDoc(collection(db, 'all_products'), {
        Code: Code,
        description: description,
        Group: Group,
        subGroup: subGroup,
        Category: Category,
        Price1: Price1,
      });

      // If image is selected, upload to Firebase Storage
      if (image) {
        const storageRef = ref(storage, `images/${docRef.id}`);
        await uploadBytes(storageRef, image);
        const imageURL = await getDownloadURL(storageRef);

        // Update Firestore document with image URL
        await updateDoc(doc(db, 'all_products', docRef.id), {
          imageURL: imageURL,
        });

        console.log('Image URL: ', imageURL);
      }

      console.log('Document written with ID: ', docRef.id);
      
      // Success alert
      alert('Product added successfully!');

    } catch (e) {
      console.error('Error adding document: ', e);
      alert('Error adding product. Please try again.');
    }
  };

  return (
    <div className='admin_main_add'>
      <Sidebar />
      <div className='addproduct_main'>
        <h3>Add product</h3>
        <form onSubmit={handleSubmit} className='add_form'>
          <label>Code:</label>
          <input type="text" value={Code} onChange={(e) => setCode(e.target.value)} />

          <label>Description:</label>
          <input type="text" value={description} onChange={(e) => setdescription(e.target.value)} />
          
          <label>Group:</label>
          <select value={Group} onChange={(e) => setGroup(e.target.value)}>
            <option value="" disabled>Select a group</option>
            {groupOptions.map((group) => (
              <option key={group} value={group}>
                {group}
              </option>
            ))}
          </select>
          
          <label>Sub Group:</label>
          <input type="text" value={subGroup} onChange={(e) => setsubGroup(e.target.value)} />
          
          <label>Category:</label>
          <select value={Category} onChange={(e) => setCategory(e.target.value)}>
            <option value="" disabled>Select a category</option>
            {categoryOptions.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>

          <label>Price1:</label>
          <input type="number" value={Price1} onChange={(e) => setPrice1(e.target.value)} />
          
          <label>Image:</label>
          <input type="file" onChange={(e) => setimage(e.target.files[0])} />
          
          <button className='add_btn' type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Addproduct_admin;
